<template>
  <div>
    <div class="line-styles mb-1">
      <div class="d-flex align-items-center h-100 text-nowrap table-responsive">
        <div class="d-flex flex-column ml-2 mt-2" style="text-decoration-color: black;">
          <h4>Situação:</h4>
        </div>
        <div class="d-flex flex-column ml-1 mr-1 justify-content-start align-items-start my-divider">
          <h5 class="">{{ this.company.situacao[0] }}</h5>
          <b-badge class="align-self-start" pill
            :class="`badge-light-${situacaoBadgeInfo.variant}
                     border-${situacaoBadgeInfo.variant}`">
            <span class="text-dark">{{ situacaoBadgeInfo.text }}</span>
          </b-badge>
        </div>
        <div class="d-flex flex-column mt-1">
          <b-button
            size="large"
            variant="outline-primary"
            class="btn-tour-skip mr-1 button-styles"
            @click="getCompanyDetails()"
          >
            <span class="align-middle">Detalhes</span>
          </b-button>
        </div>
        <div class="d-flex flex-column ml-2 mt-2 align-items-end justify-content-end w-100">
          <b-img src="@/assets/images/currency_icon.svg" class="justify-content-center mr-1" />
        </div>
        <span v-if="this.company.notas_comerciais_emitidas === 0" class="mr-2 mt-2">Nenhuma nota emitida</span>
        <span v-else class="mr-2 mt-2">{{ countNotasComerciais(this.company.notas_comerciais_emitidas) }}</span>
      </div>
    </div>

    <b-form class="auth-login-form" @submit.prevent="login">
      <h3 class="mb-1">Dados da empresa</h3>
      <b-row>
        <b-col cols="4">
          <b-form-group label="CNPJ" label-for="cnpj">
            <validation-provider name="CNPJ" rules="required|cnpj"
                                  vid="cnpj">
              <b-form-input id="cnpj" v-model="company.cnpj" type="text" v-mask="'##.###.###/####-##'" name="cnpj" :readonly="true"/>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Nome fantasia" label-for="fantasy_name">
            <validation-provider name="Nome fantasia" rules="required" vid="fantasy_name">
              <b-form-input id="fantasy_name" v-model="company.nome_fantasia"
                            name="fantasy_name" :readonly="true"/>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Razão social" label-for="legal_name">
            <validation-provider name="Razão social" rules="required" vid="legal_name">
              <b-form-input id="legal_name" v-model="company.razao_social"
                            name="legal_name" :readonly="true"/>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="4">
          <b-form-group label="Web site" label-for="website">
            <validation-provider name="Web site" vid="website">
              <b-form-input id="website" v-model="company.website"
                            name="website" :readonly="true"/>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Email" label-for="email">
            <validation-provider name="Email" rules="required|email" vid="email">
              <b-form-input id="email" v-model="company.contato_email" name="email" :readonly="true"/>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Telefone" label-for="phone">
            <validation-provider name="Telefone" rules="required|email" vid="phone">
              <b-form-input id="phone" v-model="company.contato_telefone" name="phone" type="tel" v-mask="'## ####-####'" :readonly="true"/>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="mb-1">Endereço</h3>
      <b-row>
        <b-col cols="4">
          <b-form-group label="CEP" label-for="zipcode">
            <validation-provider name="CEP" rules="required|CEP" vid="zipcode">
              <b-form-input id="zipcode" v-model="company.cep"
                            name="zipcode" type="text" v-mask="'##.###-###'" :readonly="true"/>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Estado" label-for="state">
            <validation-provider name="Estado" rules="required" vid="state">
              <b-form-input id="state" v-model="company.uf" name="state" :readonly="true"/>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Cidade" label-for="city">
            <validation-provider name="Cidade" rules="required" vid="city">
              <b-form-input id="city" v-model="company.cidade" name="city" maxlength="100" :readonly="true"/>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <b-modal id="show-details" centered hide-header hide-footer class="" size="lg">
      <div v-if="modalResult.nome" class="asd p-1">
        <div class="detail-header">
          <div class="row border-bottom align-items-center py-50">
            <div class="col-11 mb-1">
              <h3 class="mb-0 text-blue">
                <strong>Status da empresa</strong>
              </h3>
            </div>
            <div class="col-1 mb-1">
              <feather-icon
                :disabled="true"
                size="23"
                icon="XIcon"
                class="mr-75"
                style="cursor: pointer"
                :title="`Fechar modal`"
                @click="closeDetailsModal"
              />
            </div>
          </div>
          <div class="row justify-content-between mt-2 mb-1">
            <div v-if="modalResult.nome" class="col-6 d-flex">
              <h3>{{ modalResult.nome }}</h3>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <span class="text-gray" style="font-size: 20px;">{{ countDays(modalResult.updated_at) }}</span>
            </div>
          </div>
        </div>
        <div class="border-top align-items-center justify-content-end py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Envio de convite</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="inviteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${inviteBadge(modalResult).variant} border-${
                  inviteBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ inviteBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="inviteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ countDays(modalResult.envio_convite.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Cadastro na plataforma</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="plataformRegistered(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${plataformRegistered(modalResult).variant} border-${
                  plataformRegistered(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ plataformRegistered(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="plataformRegistered(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ countDays(modalResult.cadastro_plataforma.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Documentação e SCR</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="documentSCRBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${documentSCRBadge(modalResult).variant} border-${
                  documentSCRBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ documentSCRBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="documentSCRBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ countDays(modalResult.documentacao_scr.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Análise de limite</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="aprovacaoLimiteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${aprovacaoLimiteBadge(modalResult).variant} border-${
                  aprovacaoLimiteBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ aprovacaoLimiteBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="aprovacaoLimiteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ countDays(modalResult.analise_limite.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Envio de documentação</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="envioDocumentacaoBadge(modalResult).variant === 'not_applied'">
                ---
              </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${envioDocumentacaoBadge(modalResult).variant} border-${
                  envioDocumentacaoBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ envioDocumentacaoBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="envioDocumentacaoBadge(modalResult).variant === 'not_applied'">---</span>
              <p class="mb-0" v-else>{{ countDays(modalResult.envio_documentacao.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Análise da documentação</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="analiseDocumentacaoBadge(modalResult).variant === 'not_applied'">
                ---
              </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${analiseDocumentacaoBadge(modalResult).variant} border-${
                  analiseDocumentacaoBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ analiseDocumentacaoBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="analiseDocumentacaoBadge(modalResult).variant === 'not_applied'"
                >---</span
              >
              <p class="mb-0" v-else>{{ countDays(modalResult.analise_documentacao.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Apto para operar</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="aptoOperarBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${aptoOperarBadge(modalResult).variant} border-${
                  aptoOperarBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ aptoOperarBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="aptoOperarBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ countDays(modalResult.apto_operar.data) }}</p>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import CompanyForm from '@/views/client/user/components/CompanyForm.vue'
import {mask} from 'vue-the-mask'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BSpinner,
  BBadge,
  BModal
} from 'bootstrap-vue'

export default {
  name: 'GeneralData',
  components: {
    BLink,
    CompanyForm,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BModal
  },
  directives: {
    mask
  },
  data() {
    return {
      company: {
        cnpj: '',
        nome_fantasia: '',
        razao_social: '',
        website: '',
        email: '',
        phone: '',
      },
      modalResult: {},
    }
  },
  async mounted() {
    const { data } = await this.$store.dispatch('company/fetch', this.$route.params.id)
    this.company = data
  },
  methods: {
    async getCompanyDetails() {
      let params = {
        path: 'backoffice/relatorios_de_empresa',
        data: { empresa_id: this.company.id },
      }
      const { data } = await this.$store.dispatch('app/getIndexData', params)
      this.modalResult = data.data[0]
      this.$bvModal.show('show-details')
    },
    closeDetailsModal() {
      this.$bvModal.hide('show-details')
    },
    countDays(date) {
      if (date === null) return '---'

      const data1 = new Date(date)
      data1.setHours(0, 0, 0, 0)
      const data2 = new Date()
      data2.setHours(0, 0, 0, 0)

      const milisecondsDiff = (data2 - data1)
      const milissegundosPorDia = 24 * 60 * 60 * 1000

      const diferencaEmDias = Math.round(milisecondsDiff / milissegundosPorDia)

      if (diferencaEmDias === 0) return 'Hoje'

      return diferencaEmDias > 1 ? `${diferencaEmDias} dias` : `${diferencaEmDias} dia`
    },
    situacaoBadge(situacao) {
      if (situacao[0] === 'Apto para operar') return this.aptoOperarBadge(situacao[1], true)
      if (situacao[0] === 'Análise de documentação') return this.analiseDocumentacaoBadge(situacao[1], true)
      if (situacao[0] === 'Envio de documentação') return this.envioDocumentacaoBadge(situacao[1], true)
      if (situacao[0] === 'Análise de limite') return this.aprovacaoLimiteBadge(situacao[1], true)
      if (situacao[0] === 'Documentação e SCR') return this.documentSCRBadge(situacao[1], true)
      if (situacao[0] === 'Cadastro na plataforma') return this.plataformRegistered(situacao[1], true)
      if (situacao[0] === 'Envio de convite') return this.inviteBadge(situacao[1], true)
    },
    inviteBadge(item, isSituacaoBadge = null) {
      const badge = {
        aguardando_envio: {
          text: 'Aguardando envio',
          variant: 'warning',
        },
        convite_enviado: {
          text: 'Convite enviado',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.envio_convite.status]
    },
    plataformRegistered(item, isSituacaoBadge = null) {
      const badge = {
        aguardando_cadastro: {
          text: 'Aguardando cadastro',
          variant: 'warning',
        },
        cadastro_realizado: {
          text: 'Cadastrado',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.cadastro_plataforma.status]
    },
    documentSCRBadge(item, isSituacaoBadge = null) {
      const badge = {
        aguardando_envio: {
          text: 'Aguardando envio',
          variant: 'warning',
        },
        enviado_cliente: {
          text: 'Enviado pelo cliente',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.documentacao_scr.status]
    },
    aprovacaoLimiteBadge(item, isSituacaoBadge = null) {
      const badge = {
        aprovado: {
          text: 'Aprovado',
          variant: 'success',
        },
        reprovado: {
          text: 'Reprovado',
          variant: 'danger',
        },
        aguardando: {
          variant: 'warning',
          text: 'Aguardando',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.analise_limite.status]
    },
    envioDocumentacaoBadge(item, isSituacaoBadge = null) {
      const badge = {
        aguardando_envio: {
          text: 'Aguardando envio',
          variant: 'warning',
        },
        documentacao_enviada: {
          text: 'Documentação enviada',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.envio_documentacao.status]
    },
    analiseDocumentacaoBadge(item, isSituacaoBadge = null) {
      const badge = {
        aguardando: {
          text: 'Aguardando',
          variant: 'warning',
        },
        aprovado: {
          text: 'Aprovado',
          variant: 'success',
        },
        reprovada: {
          text: 'Reprovado',
          variant: 'danger',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.analise_documentacao.status]
    },
    aptoOperarBadge(item, isSituacaoBadge = null) {
      const badge = {
        sim: {
          text: 'Sim',
          variant: 'success',
        },
        nao: {
          text: 'Não',
          variant: 'danger',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.apto_operar.status]
    },
    countNotasComerciais(qtdNotas) {
      return qtdNotas === 1 ? `${qtdNotas} nota emitida` : `${qtdNotas} notas emitidas`
    }
  },
  computed: {
    situacaoBadgeInfo() {
      return this.situacaoBadge(this.company.situacao)
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-file-manager";
</style>
